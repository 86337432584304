* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}

summary::-webkit-details-marker {
    display: none;
}

body {
    background-image: url(./images/bg.jpg);
    background-position: top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #121214;
    background-attachment: fixed;
    overflow-x: hidden;
    color: #efeff1;
    letter-spacing: 2px;
}

body * {
    font-family: 'Inter', sans-serif;
    line-height: 160%;
}

header {
    margin-top: 24px;
    height: calc(120px - 24px);
}

header div a {
    width: 100px;
    margin: auto;
    background-image: linear-gradient(90deg, #791389 0%, #02ebfa 50.52%, #c7953f 100%);
    border-radius: 50%;
    display: flex;
    padding: 2px;
    transition: transform 0.3s;
}

header div:hover {
    transform: scale(1.2);
}

header div a img {
    width: 100%;
    border-radius: 50%;
}

main {
    max-width: 520px;
    width: 90%;
    margin: 24px auto;

    display: flex;
    flex-direction: column;
    gap: 18px;
}

section {
    background-image: linear-gradient(90deg, #791389 0%, #02ebfa 50.52%, #c7953f 100%);
    padding-top: 2px;
    border-radius: 10px;
}

section div {
    padding: 24px;
    padding-top: 18px;
    border-radius: 8px;
    background-color: #1f1f23;
}

section h2 {
    display: flex;
    justify-content: center;
}

section p {
    display: flex;
    justify-content: center;
}

section ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 18px;
    justify-content: center;
}

section .games-list li,
section .social-list li {
    transition: transform 0.3s;
}

section .games-list li:hover,
section .social-list li:hover {
    transform: scale(1.2);
}

.games-list img {
    width: 90px;
    border-radius: 8px;
}

/* Setup List */
section .setup-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: auto;
    justify-items: center;
    margin-top: 18px;
}

summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    gap: 10px;
}

section .setup-list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    
    margin: auto;
    justify-content: center;
    gap: 16px;
}

.setup-list .reverso {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: wrap;
    
    margin: auto;
    justify-content: center;
    gap: 16px;
}

.setup-list img {
    width: 160px;
    height: 120px;
}

.setup-list p {
    display: flex;
    font-size: 12px;
    width: 220px;
    justify-content: center;
    margin-top: 18px;
}

/* animation */
header div {
    animation: fromTop 0.7s 0.3s backwards;    
}

main section {
    animation: fromBottom 0.7s 0.2s backwards; 
}

main section:nth-child(1) {
    animation-delay: 0;
}

main section:nth-child(2) {
    animation-delay: 0.5s;
}

main section:nth-child(3) {
    animation-delay: 1s;
}

main section:nth-child(4) {
    animation-delay: 1.5s;
}

@keyframes fromTop {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fromBottom {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Estilos para telas de smartphones */
@media (max-width: 767px) {
    section .setup-list {
        max-width: 100%;
        padding: 0 16px;
    }
    
    .setup-list img {
        max-width: 100%;
        height: auto;
    }
    
    .setup-list p {
        width: 100%;
    }
}